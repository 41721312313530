<template>
  <div></div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { VueCookieNext } from "vue-cookie-next";

export default {
  props: {
    warningTime: {
      type: Number,
      default: 300000
    },
    logoutTime: {
      type: Number,
      default: 60000
    }
  },
  setup(props) {
    const warningTimer = ref(null)
    const logoutTimer = ref(null)
    const timeElapsed = ref(props.logoutTime / 1000)
    const intervalId = ref(null)
    const jwt_token = ref(null)
    const events = ref(['click', 'mousemove', 'mousedown', 'scroll', 'keydown'])
    const store = useStore()
    const lock = ref(false)
    const router = useRouter()
    const token_expiry = ref(null)
    const i18n = useI18n({ useScope: 'global' })

    function setWarningTimer() {
      // After 5 min of inactivity display popup
      warningTimer.value = setTimeout(() => {
        displayWarningMessage()
      }, props.warningTime)
    }

    function setLogoutTimer() {
      // After 5 min of inactivity display popup
      logoutTimer.value = setTimeout(() => {
        logout(jwt_token.value)
      }, props.logoutTime)
    }

    function logout(jwt_token) {
      store.dispatch('setShowPopup', false)
      store.dispatch('logout', jwt_token).then(() => {
        store.dispatch('resetToasts')

        clearTimeout(warningTimer.value)
        clearTimeout(logoutTimer.value)
        clearInterval(intervalId.value)
        events.value.forEach((event) => {
          window.removeEventListener(event, resetTimer)
        })
        router.replace('/login')
      })
    }

    function displayWarningMessage() {
      jwt_token.value = VueCookieNext.getCookie('token')
      intervalId.value = setInterval(() => timeElapsed.value--, 1000)

      // 1 minute after popup automatic logout
      setLogoutTimer()

      const popup = {
        align: 'left',
        side: false,
        component: 'AutoLogoutWarning',
        props: { timeElapsed: timeElapsed },
        primaryButton: i18n.t('errors.auto_logout.btn')
      }
      store.dispatch('setPopup', popup)
    }

    async function resetTimer() {
      if(!lock.value){
       lock.value = true
        if(!VueCookieNext.isCookieAvailable('token')){
          store.dispatch('logout')
        }
        clearTimeout(warningTimer.value)
        clearTimeout(logoutTimer.value)
        clearInterval(intervalId.value)
        if (!store.state.general.showPopup) jwt_token.value = null
        let fiveMinutesLater = new Date();
        fiveMinutesLater.setMinutes(fiveMinutesLater.getMinutes() + 5);
        if (jwt_token.value || fiveMinutesLater > token_expiry.value) {
          await store.dispatch('refreshToken')
          token_expiry.value = new Date(JSON.parse(VueCookieNext.getCookie('token_expiry')))
          timeElapsed.value = props.logoutTime / 1000
          intervalId.value = setInterval(() => timeElapsed.value--, 1000)
          setLogoutTimer()
          store.dispatch('setShowPopup', false)
        } else {
          setWarningTimer()
        }
        lock.value = false
      }
      // store.dispatch('setShowPopup', false)
    }

    onMounted(() => {
      events.value.forEach((event) => {
        window.addEventListener(event, resetTimer)
      })
      token_expiry.value = new Date(JSON.parse(VueCookieNext.getCookie('token_expiry')))
      setWarningTimer()
    })

    return {
      timeElapsed
    }
  }
}
</script>

<style scoped></style>
